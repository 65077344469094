<template lang="pug">
  Section.cy-plan-description-section(
    :title="$t('plan_settings.basic_settings.sections.plan_description.section_name')"
  )
    textarea.form-control(
      v-model="object.$model"
      type="text"
      :rows="10"
      :maxlength="500"
      :placeholder="$t('plan_settings.basic_settings.sections.plan_description.input_placeholder')"
    )
</template>
<script>
  export default {
    props: {
      object: Object
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  +styled-inputs
</style>
